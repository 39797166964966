import React from "react";
import "./Contact.css";
import { Grid, Link } from "@material-ui/core";
import SectionTitle from "../../components/SectionTitle";
import resumeData from "../../utils/resumeData";
import Typography from "@material-ui/core/Typography";

const Contact = () => {
	return (
		<Grid container>
			{/* Contact Form */}
			{/*<Grid item xs={12} md={6} lg={7} style={{ marginBottom: "20px" }}>
				<Grid container>
					<SectionTitle titleText="Contact Form" />
					<Grid item xs={12} className="contact_form">
						<Grid container spacing={3}>
							<Grid item xs={12} sm={4} md={5}>
								<TextField fullWidth name="name" label="Name" />
							</Grid>
							<Grid item xs={12} sm={4} md={5}>
								<TextField fullWidth name="email" label="Email" />
							</Grid>
							<Grid item xs={12} sm={8} md={10}>
								<TextField fullWidth name="message" label="Message" multiline rows={4} />
							</Grid>
							<Grid item xs={12} sm={8} md={10}>
								<CustomButton text="Submit" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>*/}
			{/* Contact Information */}
			<Grid item xs={12} md={6} lg={5}>
				<Grid container>
					<SectionTitle titleText="Contact Information" />
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography className="contact_info_item">
									<span>Address: </span>
									{resumeData.address}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography className="contact_info_item">
									<span>Phone: </span>
									<Link href={`tel:${resumeData.phone}`}>{resumeData.phone}</Link>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography className="contact_info_item">
									<span>Email: </span>
									<Link href={`mailto:${resumeData.email}`}>{resumeData.email}</Link>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<ul className="social_link" style={{ marginLeft: "0" }}>
									{Object.keys(resumeData.socials).map((key, index) => (
										<li className="social_link-item" key={index}>
											<a href={resumeData.socials[key].link}>
												{resumeData.socials[key].icon}
											</a>
										</li>
									))}
								</ul>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Contact;
