import React from "react";
import { withRouter, Link } from "react-router-dom";
import "./Header.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { Container } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CollectionsOutlinedIcon from "@material-ui/icons/CollectionsOutlined";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";

const useStyles = makeStyles((theme) => ({
	appBar: {
		top: 0,
		bottom: "auto",
		backgroundColor: "#66fcf1",
	},
	toolBar: {
		minHeight: "50px",
		display: "flex",
		justifyContent: "center",
	},
}));

const Header = (props) => {
	const pathName = props?.location?.pathname;
	const classes = useStyles();

	return (
		<Container className="header">
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.toolBar}>
					<Link to="/resume" className={pathName === "/resume" ? "header_link-active" : "header_link"}>
						<Tooltip title="Resume" arrow>
							<Fab
								className={
									pathName === "/resume"
										? "nav_link header_link-active"
										: "nav_link header_link"
								}
							>
								<InsertDriveFileOutlinedIcon />
							</Fab>
						</Tooltip>
					</Link>
					<Link
						to="/portfolio"
						className={pathName === "/portfolio" ? "header_link-active" : "header_link"}
					>
						<Tooltip title="Portfolio" arrow>
							<Fab
								className={
									pathName === "/portfolio"
										? "nav_link header_link-active"
										: "nav_link header_link"
								}
							>
								<WorkOutlineIcon />
							</Fab>
						</Tooltip>
					</Link>
					<Link to="/" className={pathName === "/" ? "header_link-active" : "header_link"}>
						<Tooltip title="Home" arrow>
							<Fab
								className={
									pathName === "/" ? "nav_link header_link-active" : "nav_link header_link"
								}
							>
								<HomeOutlinedIcon />
							</Fab>
						</Tooltip>
					</Link>
					<Link
						to="/certificate"
						className={pathName === "/certificate" ? "header_link-active" : "header_link"}
					>
						<Tooltip title="Certificate" arrow>
							<Fab
								className={
									pathName === "/certificate"
										? "nav_link header_link-active"
										: "nav_link header_link"
								}
							>
								<CollectionsOutlinedIcon />
							</Fab>
						</Tooltip>
					</Link>
					<Link to="/contact" className={pathName === "/contact" ? "header_link-active" : "header_link"}>
						<Tooltip title="Contact" arrow>
							<Fab
								className={
									pathName === "/contact"
										? "nav_link header_link-active"
										: "nav_link header_link"
								}
								aria-label="contact"
							>
								<ContactMailOutlinedIcon />
							</Fab>
						</Tooltip>
					</Link>
				</Toolbar>
			</AppBar>
		</Container>
	);
};

export default withRouter(Header);
