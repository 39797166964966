import React from "react";
import "./Portfolio.css";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import resumeData from "../../utils/resumeData";
import SectionTitle from "../../components/SectionTitle";
import CustomButton from "../../components/Button/CustomButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grow from "@material-ui/core/Grow";
import CastIcon from "@material-ui/icons/Cast";
import Divider from "@material-ui/core/Divider";
import { Link } from "@material-ui/core";

const Portfolio = () => {
	const [tabValue, setTabValue] = React.useState("All");
	return (
		<Grid container className="section">
			<SectionTitle titleText="Portfolio" />
			<Grid item xs={12} style={{ marginBottom: "20px" }}>
				<Tabs
					value={tabValue}
					variant="fullWidth"
					className="custom_tabs"
					onChange={(event, newValue) => setTabValue(newValue)}
				>
					<Tab
						label="All"
						value="All"
						className={tabValue === "All" ? "customTabs_item active" : "customTabs_item"}
					/>

					{[...new Set(resumeData.projects.map((project) => project.tag))].sort().map((tag, tagId) => (
						<Tab
							key={tagId}
							label={tag}
							value={tag}
							className={tabValue === tag ? "customTabs_item active" : "customTabs_item"}
						/>
					))}
				</Tabs>
			</Grid>

			{/* Projects */}
			<Grid item xs={12} className="portfolio">
				<Grid container spacing={3}>
					{resumeData.projects.map((project, proId) => (
						<React.Fragment key={proId}>
							{tabValue === project.tag || tabValue === "All" ? (
								<Grid item>
									<Grow in timeout={1000}>
										<Card elevation={7} className="customCard">
											<CardActionArea>
												<CardMedia
													className="customCard_image"
													image={project.image}
													title={project.title}
												/>
												<Divider style={{ backgroundColor: "gray" }} />
												<CardContent>
													<Typography className="customCard_title">
														{project.title}
													</Typography>
													<Link
														href={project.link}
														target="_blank"
														className="customCard_btn_link"
													>
														<CustomButton text="Live" icon={<CastIcon />} />
													</Link>
												</CardContent>
											</CardActionArea>
										</Card>
									</Grow>
								</Grid>
							) : null}
						</React.Fragment>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Portfolio;
