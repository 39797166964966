import React from "react";
import "./Resume.css";
import { Grid } from "@material-ui/core";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CastForEducationOutlinedIcon from "@material-ui/icons/CastForEducationOutlined";
import SectionTitle from "../../components/SectionTitle";
import CustomTimeline, { CustomTimelineItem } from "../../components/Timeline/CustomTimeline";
import resumeData from "../../utils/resumeData";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Contact from "../Contact/Contact";

const Resume = () => {
	return (
		<>
			{/* Education and Experience */}
			<Grid container className="section">
				<SectionTitle titleText="Resume" />
				<Grid item xs={12}>
					<Grid container>
						{/* Education*/}
						<Grid item sm={12} md={6}>
							<CustomTimeline title="Education" icon={<CastForEducationOutlinedIcon />}>
								{resumeData.educations.map((education, eduId) => (
									<CustomTimelineItem
										key={eduId}
										title={education.title}
										duration={education.duration}
										text={education.institute}
										address={education.address}
										link={education.link}
									/>
								))}
							</CustomTimeline>
						</Grid>
						{/* Experience*/}
						<Grid item sm={12} md={6}>
							<CustomTimeline title="Work Experience" icon={<WorkOutlineIcon />}>
								{resumeData.experiences.map((experience, expId) => (
									<CustomTimelineItem
										key={expId}
										title={experience.title}
										duration={experience.duration}
										text={experience.company}
										link={experience.link}
										address={experience.address}
									/>
								))}
							</CustomTimeline>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* Services */}
			{/*<Grid container className="section"></Grid>*/}
			{/* Skills */}
			<Grid container className="section">
				<SectionTitle titleText="Skills" />
				<Grid container spacing={3} justify="space-between">
					{resumeData.skills.map((skill, index) => (
						<Grid item xs={12} sm={6} md={3} key={index}>
							<Paper elevation={0} className="skill">
								<Typography variant="h6" className="skill_title">
									{skill.title}
								</Typography>
								{skill.description.map((item, dId) => (
									<Typography className="skill_description" key={dId}>
										<TimelineDot variant={"outlined"} className="skill_dot" />
										{item}
									</Typography>
								))}
							</Paper>
						</Grid>
					))}
				</Grid>
			</Grid>
			{/* Contact */}
			<Contact />
		</>
	);
};

export default Resume;
