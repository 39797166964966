import React from "react";
import { Typography, Link } from "@material-ui/core";
import "./Profile.css";
import profilePic from "../../assets/images/profile-pic.jpg";
import CustomTimeline, { CustomTimelineItem } from "../Timeline/CustomTimeline";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import resumeData from "../../utils/resumeData";
import CustomButton from "../Button/CustomButton";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  cardAction: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div elevation={7} className="profile container_shadow">
      <div className="profile_name">
        <Typography className="name">{resumeData.name}</Typography>
        <Typography className="title">{resumeData.title}</Typography>
        <CardActions disableSpacing className={classes.cardAction}>
          <Tooltip title="Hide/Show" arrow className="expand_icon">
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <figure className="profile_image">
          <img src={profilePic} alt="" />
        </figure>

        <div className="profile_information">
          <CustomTimeline icon={<PersonOutlineIcon />}>
            <CustomTimelineItem title="Name" text={resumeData.name} />
            <CustomTimelineItem title="Title" text={resumeData.title} />
            <CustomTimelineItem title="Phone" text={resumeData.phone} />
            <CustomTimelineItem title="Email" text={resumeData.email} />

            <Typography variant="h6" className="social_media">
              Social Media
            </Typography>
            <ul className="social_link">
              {Object.keys(resumeData.socials).map((key, index) => (
                <li className="social_link-item" key={index}>
                  <Link
                    href={resumeData.socials[key].link}
                    target="_blank"
                    rel="noopener"
                  >
                    {resumeData.socials[key].icon}
                  </Link>
                </li>
              ))}
            </ul>
          </CustomTimeline>
          <div className="btn_container">
            <CustomButton icon={<GetAppOutlinedIcon />}>
              <Link
                href={resumeData.resumeLink}
                target="blank"
                rel="noopener"
                className="link_btn"
              >
                Download Resume
              </Link>
            </CustomButton>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default Profile;
