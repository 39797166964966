import React from "react";
import { Grid, Typography } from "@material-ui/core";

const SectionTitle = ({titleText}) => {
	return (
		<Grid item className="section_title">
			<span></span>
			<Typography variant="h6" className="section_title_text">
				{titleText}
			</Typography>
		</Grid>
	);
};

export default SectionTitle;
