import React from "react";
import "./Home.css";
import { Grid, Link, Typography } from "@material-ui/core";
import resumeData from "../../utils/resumeData";
import SectionTitle from "../../components/SectionTitle";

const Home = () => {
  return (
    <>
      {/* About Me */}
      <Grid container spacing={1} className="section">
        <SectionTitle titleText="About Me" />
        <Grid item xs={12}>
          <Typography variant="body2" className="aboutme_text">
            {resumeData.resume.aboutme}
          </Typography>
        </Grid>
        <SectionTitle titleText="Blog & Tools" />
        <Grid item xs={12}>
          <Typography className="blog_link">
            <span>{"Main: "}</span>
            <Link href="https://iamsajan.com" target="_blank" rel="noopener">
              {"https://iamsajan.com"}
            </Link>
          </Typography>
          <Typography className="blog_link">
            <span>{"Tools: "}</span>
            <Link
              href="https://tools.iamsajan.com"
              target="_blank"
              rel="noopener"
            >
              {"https://tools.iamsajan.com"}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {/* <Quotes /> */}
    </>
  );
};

export default Home;
