import React from "react";
import "./CustomButton.css";
import { Button } from "@material-ui/core";

const CustomButton = ({ text, icon, path, children }) => {
	return (
		<Button
			className={path === "/contact" ? "custom_btn custom_btn-active" : "custom_btn"}
			endIcon={icon ? <span className="btn_icon_container">{icon}</span> : null}
		>
			{children ? children : <span className="btn_text">{text}</span>}
		</Button>
	);
};

export default CustomButton;
