import {
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  Twitter,
} from "@material-ui/icons";
import name from "../../src/assets/images/portfolio/name.png";
import randomAdvice from "../../src/assets/images/portfolio/random-advice.png";
import tms from "../../src/assets/images/portfolio/tms-logo.png";
import covid19 from "../../src/assets/images/portfolio/covid19.jpg";
import onewindow from "../../src/assets/images/portfolio/onewindow.png";
import rajahrowing from "../../src/assets/images/portfolio/rajahrowing.svg";
import japaneseQuiz from "../../src/assets/images/portfolio/japanese-quiz.png";
import quizExamPortal from "../../src/assets/images/portfolio/quizExamPortal.jpg";
import contactManager from "../../src/assets/images/portfolio/contact-manager.png";
import kathmanduEntrance from "../../src/assets/images/portfolio/kathmandu-entrance.jpg";
import aadiminnovation from "../../src/assets/images/portfolio/aadim-innovation-logo-full.png";
import mikha from "../../src/assets/images/portfolio/mikha.png";
import hackerrankCss from "../../src/assets/images/certificates/hackerrank-css.png";
import hackerrankJavaBasic from "../../src/assets/images/certificates/hackerrank-java(basic).png";
import udemyProjectDevelopmentJava from "../../src/assets/images/certificates/Project Development Using Java for Beginners 2021.jpg";
import courseraOopInJava from "../../src/assets/images/certificates/Object Oriented Programming In Java.png";
import courseraOopWithJava from "../../src/assets/images/certificates/Object-Oriented Programming with Java.jpeg";
import udemySpringwithSpringBoot from "../../src/assets/images/certificates/Spring Framework for Beginners with Spring Boot.jpg";
import courseraJavaProblemSolving from "../../src/assets/images/certificates/Java Programming Solving Problems with Software.png";
import dataCampSQL from "../../src/assets/images/certificates/Introduction to SQL.png";
import dataCampSQLJoin from "../../src/assets/images/certificates/Joining Data in SQL.png";
import soloLearnSQLFundamental from "../../src/assets/images/certificates/SQL fundamental course.jpg";
import eduonixWebDeveloper from "../../src/assets/images/certificates/Become-A-Certified-Web-Developer-From-Scratch.jpg";
import soloLearnJavaTutorial from "../../src/assets/images/certificates/Java Tutorial.jpg";
import pirpleFrontend from "../../src/assets/images/certificates/Frontendcertificate.png";
import codecademyCss from "../../src/assets/images/certificates/Learn CSS course.png";
import codecademyHTML from "../../src/assets/images/certificates/Learn HTML.png";
import codecademyResponsiveDesign from "../../src/assets/images/certificates/Learn Responsive Design Course.png";
import codecademySQL from "../../src/assets/images/certificates/Learn SQL Course.png";
import soloLearnHtml from "../../src/assets/images/certificates/html5 sololearn.jpg";
import microsoftstudentambassadors from "../../src/assets/images/certificates/microsoft student ambassadors.png";
import angularbasic from "../../src/assets/images/certificates/angular-basic.png";

const resumeData = {
  name: "Sajan K.C.",
  title: "Full Stack Developer",
  birthday: "01 July 1996",
  email: "sajankcofficial@gmail.com",
  address: "Budhanilkantha-13, Kathmandu",
  phone: "+977 9843337779",
  resumeLink:
    "https://drive.google.com/file/d/1Wa7csC4NjlXsAjPgc9Sx-l1P0ZHeJq0u/view",

  socials: {
    Facebook: {
      link: "https://www.facebook.com/sajan96kc/",
      text: "Sajan Kc",
      icon: <Facebook />,
    },
    Instagram: {
      link: "https://www.instagram.com/sajan96kc/",
      text: "Sajan Kc",
      icon: <Instagram />,
    },
    LinkedIn: {
      link: "https://www.linkedin.com/in/sajankc/",
      text: "Sajan Kc",
      icon: <LinkedIn />,
    },
    Github: {
      link: "https://github.com/SajanKc",
      text: "Sajan Kc",
      icon: <GitHub />,
    },
    Twitter: {
      link: "https://twitter.com/sajan96kc",
      text: "Sajan Kc",
      icon: <Twitter />,
    },
  },
  resume: {
    aboutme:
      "I'm Sajan Kc, an intermediate-level coder with a passion for exploring new ideas and solving problems through programming. I've had the opportunity to work with various programming languages and continue to expand my coding skills. Beyond my professional interests, I have a rich array of hobbies that keep me engaged. I love diving into a good book, immersing myself in the virtual worlds of video games, and coding for both work and personal projects. In my downtime, you'll often find me enjoying a compelling movie or embarking on exciting journeys to new places, where I can further fuel my curiosity and gain fresh perspectives. My enthusiasm for computer programming runs deep, and I'm always eager to tackle new challenges and learn more in this ever-evolving field.",
  },
  experiences: [
    {
      title: "Cloud Worker",
      duration: "2017 - 2019",
      company: "Cloud Factory",
      address: "Bhaisepati, Lalitpur, Nepal",
      link: "https://www.cloudfactory.com.np/",
    },
    {
      title: "Software Engineer Intern",
      duration: "Jan-Jun 2022",
      company: "Aadim Innovation",
      address: "Chabahil, Chuchepati, Kathmandu, Nepal",
      link: "http://aadiminnovation.com/",
    },
    {
      title: "Technical Consultant",
      duration: "Sep 2022-Present",
      company: "Decision Trust Pvt Ltd",
      address: "Kathmandu, Nepal",
      link: "https://decisiontrust.com/",
    },
    {
      title: "Trainee Software Engineer",
      duration: "14 April-16 July 2023",
      company: "Bitskarft Pvt. Ltd.",
      address: "Nagpokhari, Kathmandu, Nepal",
      link: "https://bitskraft.com",
    },
    {
      title: "Software Engineer",
      duration: "17 July-Present",
      company: "Bitskarft Pvt. Ltd.",
      address: "Nagpokhari, Kathmandu, Nepal",
      link: "https://bitskraft.com",
    },
  ],
  educations: [
    {
      title: "Basic & Secondary School",
      duration: "2000 - 2012",
      institute: "Shree Janajagriti Secondary School",
      address: "Budhanilkantha-11, Kathmandu, Nepal",
      link: "https://www.facebook.com/janajagritiss/",
    },
    {
      title: "Higher Secondary School",
      duration: "2012 - 2014",
      institute: "St. Lawrence College",
      address: "Chabahil, Pipalbot, Kathmandu, Nepal",
      link: "https://www.stlawrence.edu.np/",
    },
    {
      title: "Bachelor",
      duration: "2018 - 2023",
      institute: "Aadim National College",
      address: "Chabahil, Chuchepati, Kathmandu, Nepal",
      link: "https://aadimcollege.edu.np/",
    },
  ],
  skills: [
    {
      title: "Front-end",
      description: [
        "HTML",
        "CSS",
        "JavaScript (Angular, React, NextJs)",
        "Bootstrap",
        "Material UI",
      ],
    },
    {
      title: "Back-end",
      description: ["Java (Spring Boot)", "PHP"],
    },
    {
      title: "Databases",
      description: ["SQL", "MongoDB"],
    },
    {
      title: "Source Control",
      description: ["Git", "Github", "Gitlab", "Bitbucket"],
    },
  ],
  projects: [
    {
      tag: "Web",
      image: onewindow,
      title: "One Window International",
      link: "https://onewindowintl.com/",
    },
    {
      tag: "Web",
      image: tms,
      title: "Total Management System",
      link: "http://tms.com.np/",
    },
    {
      tag: "Web",
      image: rajahrowing,
      title: "Rajah Rowing",
      link: "https://rajahrowing.com/",
    },
    {
      tag: "Web",
      image: aadiminnovation,
      title: "Aadim Innovation",
      link: "http://aadiminnovation.com/",
    },
    {
      tag: "Angular",
      image: quizExamPortal,
      title: "Quiz Exam Portal",
      link: "https://quiz-exam.netlify.app/",
    },
    {
      tag: "React",
      image: covid19,
      title: "Covid19 App",
      link: "https://covid1nine.netlify.app/",
    },
    {
      tag: "React",
      image: contactManager,
      title: "Contact Manager",
      link: "https://mycontact-manager.netlify.app",
    },
    {
      tag: "React",
      image: randomAdvice,
      title: "Random Advice",
      link: "https://givemerandomadvice.netlify.app/",
    },
    {
      tag: "React",
      image: japaneseQuiz,
      title: "Japanese Quiz",
      link: "https://japanese-quiz.netlify.app/",
    },
    {
      tag: "React",
      image: name,
      title: "Movie Search App",
      link: "https://sajankc.github.io/movie-search-app/",
    },
    {
      tag: "React",
      image: name,
      title: "BCA MCQ",
      link: "https://sajankc.github.io/bca-mcq/",
    },
    {
      tag: "React",
      image: name,
      title: "YouTube Clone",
      link: "https://sajankc.github.io/youtube/",
    },
    {
      tag: "Angular",
      image: mikha,
      title: "Mikha",
      link: "https://mikha.bitskraft.com/",
    },
    {
      tag: "Java",
      image: mikha,
      title: "Mikha",
      link: "https://mikha.bitskraft.com/",
    },
    {
      tag: "Java",
      image: name,
      title: "SpringBootEmail",
      link: "https://github.com/SajanKc/SpringBootEmail",
    },
    {
      tag: "Java",
      image: name,
      title: "Ecommerce REST API",
      link: "https://github.com/SajanKc/EcommerceRESTAPI",
    },
    {
      tag: "Java",
      image: name,
      title: "HSEB-Marksheet itext7",
      link: "https://github.com/SajanKc/HSEB-Marksheet-itext7",
    },
    {
      tag: "Web",
      image: kathmanduEntrance,
      title: "Kathmandu Entrance",
      link: "https://kathmanduentrance.com",
    },
    {
      tag: "Web",
      image: name,
      title: "Infix to Postfix Converter",
      link: "https://infixtopostfix.iamsajan.com/",
    },
    {
      tag: "NextJs",
      image: name,
      title: "Base64 Converter",
      link: "https://tools.iamsajan.com/base64",
    },
    {
      tag: "NextJs",
      image: name,
      title: "Image to Base64 Converter",
      link: "https://tools.iamsajan.com/image-to-base64",
    },
    {
      tag: "NextJs",
      image: name,
      title: "Base64 To Image Converter",
      link: "https://tools.iamsajan.com/base64-to-image",
    },
    {
      tag: "NextJs",
      image: name,
      title: "Md5 Encoder",
      link: "https://tools.iamsajan.com/md5",
    },
    {
      tag: "NextJs",
      image: name,
      title: "Word / Letter Counter",
      link: "https://tools.iamsajan.com/word-counter",
    },
    {
      tag: "NextJs",
      image: name,
      title: "Random Password Generator",
      link: "https://tools.iamsajan.com/random-password-generator",
    },
    {
      tag: "NextJs",
      image: name,
      title: "Date Converter",
      link: "https://tools.iamsajan.com/date-converter",
    },
  ],
  certificates: [
    {
      tag: "Angular",
      title: "Angular (Basic)",
      institute: "HackerRank",
      credentialId: "6c50ffd5ab27",
      link: "https://www.hackerrank.com/certificates/6c50ffd5ab27",
      image: angularbasic,
      date: "August 2022",
    },
    {
      tag: "Others",
      title: "Microsoft Learn Student Ambassadors",
      institute: "Microsoft",
      credentialId: "e6485c7a-84ed-44f5-bb1f-7e1de238dfd8",
      link: "https://studentambassadors.microsoft.com/certificate/e6485c7a-84ed-44f5-bb1f-7e1de238dfd8",
      image: microsoftstudentambassadors,
      date: "July 2021",
    },
    {
      tag: "Java",
      title: "Java (Basic)",
      institute: "HackerRank",
      credentialId: "25db60e7f9ad",
      link: "https://www.hackerrank.com/certificates/25db60e7f9ad",
      image: hackerrankJavaBasic,
      date: "June 2021",
    },
    {
      tag: "Web",
      title: "Css Certificate",
      institute: "HackerRank",
      credentialId: "93766879b8c0",
      link: "https://www.hackerrank.com/certificates/93766879b8c0",
      image: hackerrankCss,
      date: "May 2021",
    },
    {
      tag: "Java",
      title: "Project Development Using JAVA for Beginners - 2021",
      institute: "Udemy",
      credentialId: "UC-7425a381-0a3e-4d82-ae6c-9796f9ec22d3",
      link: "https://www.udemy.com/certificate/UC-7425a381-0a3e-4d82-ae6c-9796f9ec22d3/",
      image: udemyProjectDevelopmentJava,
      date: "May 2021",
    },
    {
      tag: "Java",
      title: "Object Oriented Programming in Java",
      institute: "Coursera",
      credentialId: "3S2TJW239426",
      link: "https://coursera.org/share/d12590b54cfefea2da002ffa9912aff3",
      image: courseraOopInJava,
      date: "July 2020",
    },
    {
      tag: "Java",
      title: "Object-Oriented Programming with Java",
      institute: "Coursera",
      credentialId: "SW5W6AQ2ENSX",
      link: "https://coursera.org/share/5ab11b2952207c9c93b3d62a4af0fa5c",
      image: courseraOopWithJava,
      date: "July 2020",
    },
    {
      tag: "Java",
      title: "Spring Framework for Beginners with Spring Boot",
      institute: "Udemy",
      credentialId: "UC-107629e1-f0d3-4e42-8cd8-dfb2e8b37d00",
      link: "https://www.udemy.com/certificate/UC-107629e1-f0d3-4e42-8cd8-dfb2e8b37d00/",
      image: udemySpringwithSpringBoot,
      date: "July 2020",
    },
    {
      tag: "Java",
      title: "Java Programming: Solving Problems with Software",
      institute: "Coursera",
      credentialId: "SPW3B6CLBPZV",
      link: "https://www.coursera.org/account/accomplishments/certificate/SPW3B6CLBPZV",
      image: courseraJavaProblemSolving,
      date: "June 2020",
    },
    {
      tag: "Database",
      title: "Introduction to SQL",
      institute: "DataCamp",
      credentialId: "13986962",
      link: "https://www.datacamp.com/statement-of-accomplishment/course/25e3350c33f3c043f884cfc2f4f90f38d45c6119",
      image: dataCampSQL,
      date: "May 2020",
    },
    {
      tag: "Database",
      title: "Joining Data in SQL",
      institute: "DataCamp",
      credentialId: "14021103",
      link: "https://www.datacamp.com/statement-of-accomplishment/course/93c73656bea7441ee1f087f9a5db1ee7cbcc2869",
      image: dataCampSQLJoin,
      date: "May 2020",
    },
    {
      tag: "Web",
      title: "SQL Fundamentals",
      institute: "SoloLearn",
      credentialId: "1060-846485",
      link: "https://www.sololearn.com/Certificate/1014-846485/jpg/",
      image: soloLearnHtml,
      date: "May 2020",
    },
    {
      tag: "Database",
      title: "SQL Fundamentals",
      institute: "SoloLearn",
      credentialId: "1060-846485",
      link: "https://www.sololearn.com/Certificate/1060-846485/jpg/",
      image: soloLearnSQLFundamental,
      date: "May 2020",
    },
    {
      tag: "Web",
      title: "Become A Certified Web Developer From Scratch",
      institute: "Eduonix Learning Solutions Pvt Ltd",
      credentialId: "b914d3a6f0",
      link: "https://www.eduonix.com/certificate/b914d3a6f0",
      image: eduonixWebDeveloper,
      date: "April 2020",
    },
    {
      tag: "Java",
      title: "Java Tutorial",
      institute: "SoloLearn",
      credentialId: "1068-846485",
      link: "https://www.sololearn.com/Certificate/1068-846485/jpg/",
      image: soloLearnJavaTutorial,
      date: "April 2020",
    },
    {
      tag: "Web",
      title: "Frontend Fundamentals",
      institute: "Pirple",
      credentialId: "16762709",
      link: "https://www.credential.net/5db4fcad-5b33-460b-9b2b-a67114a37d69",
      image: pirpleFrontend,
      date: "April 2020",
    },
    {
      tag: "Web",
      title: "Learn CSS Course",
      institute: "Codecademy",
      credentialId: "9a5bb1fc45b4281af1fffec93b0aaf05",
      link: "https://www.codecademy.com/profiles/SajanKc/certificates/9a5bb1fc45b4281af1fffec93b0aaf05",
      image: codecademyCss,
      date: "May 2019",
    },
    {
      tag: "Web",
      title: "Learn HTML Course",
      institute: "Codecademy",
      credentialId: "9eb0741e5ebef1f9f58a53bfac67d3a7",
      link: "https://www.codecademy.com/profiles/SajanKc/certificates/9eb0741e5ebef1f9f58a53bfac67d3a7",
      image: codecademyHTML,
      date: "May 2019",
    },
    {
      tag: "Web",
      title: "Learn Responsive Design Course",
      institute: "Codecademy",
      credentialId: "3a62023b0054dc793edc0adecd715fd7",
      link: "https://www.codecademy.com/profiles/SajanKc/certificates/3a62023b0054dc793edc0adecd715fd7",
      image: codecademyResponsiveDesign,
      date: "April 2019",
    },
    {
      tag: "Database",
      title: "Learn SQL Course",
      institute: "Codecademy",
      credentialId: "042a4e5884e3eb6ea1f2a12be6abb851",
      link: "https://www.codecademy.com/profiles/SajanKc/certificates/042a4e5884e3eb6ea1f2a12be6abb851",
      image: codecademySQL,
      date: "December 2019",
    },
  ],
};
export default resumeData;
