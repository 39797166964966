import React from "react";
import "./Footer.css";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Typography } from "@material-ui/core";
import resumeData from "../../utils/resumeData";
import { Link } from "@material-ui/core";

const Footer = () => {
	return (
		<div className="footer">
			<div className="footer_left">
				<Typography className="footer_name">{resumeData.name}</Typography>
			</div>
			<div className="footer_right">
				<Typography className="footer_copyright">
					Made with <FavoriteIcon style={{ color: "red" }} /> by{" "}
					<Link href="https://kcsajan.com.np" target="_blank" rel="noopener">
						Sajan Kc
					</Link>
				</Typography>
			</div>
		</div>
	);
};

export default Footer;
