import { Container, Grid } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Profile from "./components/Profile/Profile";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Portfolio from "./pages/Portfolio/Portfolio";
import Resume from "./pages/Resume/Resume";
import Certificate from "./pages/Certificate/Certificate";
import Contact from "./pages/Contact/Contact";
import Error from "./pages/Error/Error";

function App() {
	return (
		<Container className="app">
			<Grid container spacing={5}>
				<Grid item xs={12} md={4} lg={3}>
					<Profile />
				</Grid>
				<Grid item xs md={8} lg={9}>
					<Router>
						<Header />
						<div className="main_content container_shadow">
							<Switch>
								<Route exact path="/">
									<Home />
								</Route>
								<Route exact path="/portfolio">
									<Portfolio />
								</Route>
								<Route exact path="/resume">
									<Resume />
								</Route>
								<Route exact path="/certificate">
									<Certificate />
								</Route>
								<Route exact path="/contact">
									<Contact />
								</Route>
								<Route>
									<Error />
								</Route>
							</Switch>
						</div>
					</Router>
					<Footer />
				</Grid>
			</Grid>
		</Container>
	);
}

export default App;
