import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import "./CustomTimeline.css";
import { Typography } from "@material-ui/core";
import CustomButton from "../Button/CustomButton";
import { Link } from "@material-ui/core";

const CustomTimeline = ({ title, icon, children }) => {
	return (
		<Timeline className={"timeline"}>
			{/*Item Header */}
			<TimelineItem className={"timeline_firstItem"}>
				<TimelineSeparator>
					<TimelineDot className={"timeline_dot_header"}>{icon}</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					<Typography variant="h6" className={"timeline_header"}>
						{title}
					</Typography>
				</TimelineContent>
			</TimelineItem>
			{children}
		</Timeline>
	);
};

export const CustomTimelineSeparator = () => (
	<TimelineSeparator className={"separator_padding"}>
		<TimelineDot variant="outlined" className={"timeline_dot"} />
		<TimelineConnector />
	</TimelineSeparator>
);

export const CustomTimelineItem = ({ title, text, link, duration, address }) => (
	<TimelineItem>
		<CustomTimelineSeparator />
		{duration ? (
			<TimelineContent className="timeline_content resume_after">
				<span>{title}</span> <br />
				<div style={{ margin: "13px 0 10px 0" }}>
					<CustomButton text={duration} />
				</div>
				{link ? (
					<Typography variant="body1" className="timelineItem_text">
						<Link href={link} className="timeline_item_link" target="_blank" rel="noopener">
							{text}
						</Link>
					</Typography>
				) : (
					<Typography variant="body1" className="timelineItem_text">
						{text}
					</Typography>
				)}
				<Typography variant="body2" className="timelineItem_text_address">
					{address}
				</Typography>
			</TimelineContent>
		) : (
			<TimelineContent className="timeline_content">
				<span>{title}</span>
				{link ? (
					<Typography>
						<Link href={link} className="timeline_item_link" target="_blank" rel="noopener">
							{text}
						</Link>
					</Typography>
				) : (
					<Typography className="timelineItem_text">{text}</Typography>
				)}
			</TimelineContent>
		)}
	</TimelineItem>
);

export default CustomTimeline;
