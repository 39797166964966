import React from "react";
import { Link } from "react-router-dom";
import "./Error.css";
import Typography from "@material-ui/core/Typography";
import CustomButton from "../../components/Button/CustomButton";
import { HomeOutlined } from "@material-ui/icons";

const Error = () => {
	return (
		<div className="error">
			<Typography variant="h1">Sorry !!!</Typography>
			<Typography variant="h5">Page Not Found 404</Typography>
			<br />
			<div className="btn_container">
				<CustomButton icon={<HomeOutlined />}>
					<Link href="/" target="blank" rel="noopener" className="link_btn">
						Take Me Home
					</Link>
				</CustomButton>
			</div>
		</div>
	);
};

export default Error;
