import React from "react";
import "./Certificate.css";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import resumeData from "../../utils/resumeData";
import SectionTitle from "../../components/SectionTitle";
import CustomButton from "../../components/Button/CustomButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grow from "@material-ui/core/Grow";
import CastIcon from "@material-ui/icons/Cast";
import Divider from "@material-ui/core/Divider";

const Certificate = () => {
	const [tabValue, setTabValue] = React.useState("All");
	return (
		<Grid container className="section">
			<SectionTitle titleText="Certificates" />
			<Grid item xs={12} style={{ marginBottom: "20px" }}>
				<Tabs
					value={tabValue}
					variant="fullWidth"
					className="custom_tabs"
					onChange={(event, newValue) => setTabValue(newValue)}
				>
					<Tab
						label="All"
						value="All"
						className={tabValue === "All" ? "customTabs_item active" : "customTabs_item"}
					></Tab>

					{[...new Set(resumeData.certificates.map((certificate) => certificate.tag))]
						.sort()
						.map((tag, tagKey) => (
							<Tab
								key={tagKey}
								label={tag}
								value={tag}
								className={tabValue === tag ? "customTabs_item active" : "customTabs_item"}
							/>
						))}
				</Tabs>
			</Grid>

			{/* Certificates */}
			<Grid item xs={12} className="certificate">
				<Grid container spacing={3}>
					{resumeData.certificates.map((certificate, certificateId) => (
						<React.Fragment key={certificateId}>
							{tabValue === certificate.tag || tabValue === "All" ? (
								<Grid item>
									<Grow in timeout={1000}>
										<Card elevation={7} className="customCard">
											<CardActionArea>
												<CardMedia
													className="customCard_image"
													image={certificate.image}
													title={certificate.title}
												/>
												<Divider style={{ backgroundColor: "gray" }} />
												<CardContent>
													<Typography className="customCard_title">
														{certificate.title}
													</Typography>
													<Typography className="customCard_subtitle">
														{certificate.institute}
													</Typography>
													<Typography className="customCard_date">
														{certificate.date}
													</Typography>
													<a
														href={certificate.link}
														className="customCard_btn_link"
													>
														<CustomButton
															text="Orginal"
															icon={<CastIcon />}
														/>
													</a>
												</CardContent>
											</CardActionArea>
										</Card>
									</Grow>
								</Grid>
							) : null}
						</React.Fragment>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Certificate;
